<template>
  <footer class="bg-gray-600">
    <Container class="pt-20 lg:pt-56"
               data-nosnippet
    >
      <div class="grid w-full pb-10 lg:pb-16 lg:grid-cols-3 justify-items-center lg:justify-items-start">
        <div>
          <SvgOutfit7Logo class="text-white h-[30px]" />
          <ButtonBase :label="contactCTA.title"
                      class="mt-10"
                      type="filled"
                      :href="contactCTA.href"
          />
        </div>
        <nav class="grid justify-center mt-12 space-y-6 text-base text-center lg:text-2xl lg:text-left lg:mt-0 lg:justify-items-start">
          <FooterLink v-for="link in navigation"
                      :key="link.id"
                      :to="link.url"
                      class="after:h-0.5"
          >
            {{ link.title }}
          </FooterLink>
        </nav>
        <div class="mt-12 lg:ml-auto lg:mt-0">
          <ButtonBase :label="partnersCTA.title"
                      class="w-full"
                      type="outline"
                      :href="partnersCTA.href"
          />
          <ButtonBase :label="venturesCTA.title"
                      class="mt-6 w-full"
                      type="outline"
                      :href="venturesCTA.href"
          />
          <p class="w-full mt-12 text-sm text-center text-gray-100 lg:w-auto lg:text-left">
            Check also
          </p>
          <ButtonBase icon="top-right"
                      class="mt-4 lg:mt-5"
                      href="https://outfit7neo.com/"
                      label="Visit Outfit7 Neo"
                      type="gradient"
                      @click="handleHDClick"
          >
            <SvgOutfit7neoLogo class="h-6 mr-16" />
          </ButtonBase>
        </div>
      </div>
      <FooterSocial />
    </Container>

    <FooterLegal />
  </footer>
</template>

<script setup lang="ts">
import navigation from '../content/navigation/footer.json'
import { useNuxtApp } from '#imports'

const { $event } = useNuxtApp()

const contactCTA = {
  title: 'Contact us',
  href: '/contact-us'
}

const partnersCTA = {
  title: 'Outfit7 for Partners',
  href: '/partners'
}

const venturesCTA = {
  title: 'Outfit7 Joint Ventures',
  href: '/our-story/joint-ventures'
}

function handleHDClick() {
  $event('Link', { props: { Footer: 'https://outfit7neo.com/' } })
}
</script>
